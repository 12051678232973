import { AuthType } from 'providers/AuthProvider';

const AUTH_TOKEN = 'authToken';
const AUTH_TYPE = 'authType';
const LAST_SIGNUP_EMAIL = 'lastSignUpEmail';
const LAST_PUBLIC_ROUTE = 'lastPublicRoute';
const FRAUD_SESSION_ID = 'fraudSessionId';

export const storeAuthToken = (authToken: string) => localStorage.setItem(AUTH_TOKEN, authToken);
export const getAuthToken = () => localStorage.getItem(AUTH_TOKEN);
export const clearAuthToken = () => localStorage.removeItem(AUTH_TOKEN);

export const storeAuthType = (authToken: AuthType) => localStorage.setItem(AUTH_TYPE, authToken);
export const getAuthType = () => localStorage.getItem(AUTH_TYPE) as AuthType;
export const clearAuthType = () => localStorage.removeItem(AUTH_TYPE);

export const storeLastSignUpEmail = (email: string) => localStorage.setItem(LAST_SIGNUP_EMAIL, email);
export const getLastSignUpEmail = () => localStorage.getItem(LAST_SIGNUP_EMAIL);
export const clearLastSignUpEmail = () => localStorage.removeItem(LAST_SIGNUP_EMAIL);

export const storeLastPublicRoute = (route: string) => localStorage.setItem(LAST_PUBLIC_ROUTE, route);
export const getLastPublicRoute = () => localStorage.getItem(LAST_PUBLIC_ROUTE);
export const clearLastPublicRoute = () => localStorage.removeItem(LAST_PUBLIC_ROUTE);

export const storeFraudSessionId = (sessionId: string) => localStorage.setItem(FRAUD_SESSION_ID, sessionId);
export const getFraudSessionId = () => localStorage.getItem(FRAUD_SESSION_ID);
export const clearFraudSessionId = () => localStorage.removeItem(FRAUD_SESSION_ID);

export const clearStorage = () => localStorage.clear();
