import Lightbox from 'yet-another-react-lightbox';

import 'yet-another-react-lightbox/styles.css';

interface Props {
  photos: string[];
  isFullScreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
}
export const CarouselFullScreen = ({ photos, isFullScreen, setIsFullScreen }: Props) => {
  return (
    <Lightbox
      open={isFullScreen}
      close={() => setIsFullScreen(false)}
      slides={photos.map(photo => ({ src: photo }))}
      styles={{ container: { backgroundColor: 'rgba(0, 0, 0, .8)' } }}
    />
  );
};
