import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text1, Text1Bold } from 'components/typography/Texts';

const NoContentContainer = styled.div`
  height: 120px;
  background-color: ${theme.color.lighterGray};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  ${theme.mq.phone} {
    flex-direction: column;
  }
`;

const NoContentButton = styled(Text1Bold)`
  color: ${theme.color.red};
  text-decoration: underline;
  cursor: pointer;
`;

interface Props {
  text: string;
  buttonText: string;
  onClick: () => void;
}

export const NoContent = ({ text, buttonText, onClick }: Props) => {
  return (
    <NoContentContainer>
      <Text1>{text}</Text1>
      <NoContentButton onClick={onClick}>{buttonText}</NoContentButton>
    </NoContentContainer>
  );
};
