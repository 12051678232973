import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ITEMS_SLICE_SIZE } from 'assets/constants/listSizes';
import { theme } from 'assets/styles/theme';
import { CreateButton } from 'components/buttons/CreateButton';
import { RedTextButton } from 'components/buttons/RedTextButton';
import { LoadingContainer } from 'components/containers/Containers';
import { H4 } from 'components/typography/Headers';
import { LoadingIndicator } from 'components/ui-elements/LoadingIndicator';
import { resolveTileClick } from 'features/my-profile/my-activity/utils/resolveTileClick';
import { useResponsive } from 'hooks/useResponsive';
import { useModal } from 'providers/ModalProvider';
import useMyItems from 'services/useMyItems';

import { FilterButtons } from './FilterButtons';
import { MyActivityItemTile } from './MyActivityItemTile';
import { NoContent } from './NoContent';

const Container = styled.div`
  margin-top: 48px;

  ${theme.mq.phone} {
    margin-top: 24px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;

  ${theme.mq.phone} {
    gap: 12px;
  }
`;

const TileContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  ${theme.mq.tablet} {
    gap: 12px;
  }
`;

export const HotDealsSection = () => {
  const { t } = useTranslation('myProfile');
  const { state, handleFilterChange, filter } = useMyItems();
  const { showGetApp } = useModal();
  const onAddClick = () => showGetApp({ appAreaType: 'ADD_ITEM' });
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const renderLoading = () => (
    <LoadingContainer>
      <LoadingIndicator />
    </LoadingContainer>
  );

  const renderItems = () => (
    <TileContainer>
      {state.items.slice(0, ITEMS_SLICE_SIZE).map(item => (
        <MyActivityItemTile
          key={`${item.id}_${item.orderId}`}
          item={item}
          onClick={(itemId, orderId) => resolveTileClick(filter, navigate, itemId, orderId)}
        />
      ))}
    </TileContainer>
  );

  const renderNoContent = () => (
    <NoContent text={t('my-activity.no-items')} buttonText={t('my-activity.add-first')} onClick={onAddClick} />
  );

  const resolveButtonText = () => (isMobile ? t('my-activity.add') : t('my-activity.add-item'));

  const renderContent = () => {
    if (state.loading) return renderLoading();
    if (state.items && state.items.length > 0) return renderItems();
    return renderNoContent();
  };

  return (
    <Container>
      <HeaderContainer>
        <InnerContainer>
          <H4>{t('my-activity.hot-deals')}</H4>
          <CreateButton onClick={onAddClick}>{resolveButtonText()}</CreateButton>
          <RedTextButton onClick={() => navigate('/my-profile/my-activity/hot-deals')}>
            {t('my-activity.see-all')}
          </RedTextButton>
        </InnerContainer>
        <FilterButtons handleFilterChange={handleFilterChange} />
      </HeaderContainer>
      {renderContent()}
    </Container>
  );
};
