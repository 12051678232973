import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import errorIcon from 'assets/svg/error-icon.svg';
import successfulIcon from 'assets/svg/successful-icon.svg';
import { H2 } from 'components/typography/Headers';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin: 24px auto;
`;

const StatusIcon = styled.img`
  width: 210px;
  height: 210px;
  margin: 48px 0;

  ${theme.mq.tablet} {
    width: 160px;
    height: 160px;
    margin: 24px 0;
  }
`;

export const StyledTitle = styled(H2)`
  text-align: center;
  white-space: pre-line;
`;

export const InfoSection = ({ onSuccess }: any) => {
  return (
    <Section>
      {onSuccess ? (
        <StatusIcon alt={'Success Icon'} src={successfulIcon} />
      ) : (
        <StatusIcon alt={'Error Icon'} src={errorIcon} />
      )}
    </Section>
  );
};

export const InfoSectionWrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 24px auto;
`;
