import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

export const Text0 = styled.p`
  margin: 0;
  font-family: ${theme.font.family.text};
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;

  ${theme.mq.phone} {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const Text1 = styled.p`
  margin: 0;
  font-family: ${theme.font.family.text};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  ${theme.mq.phone} {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const Text2 = styled(Text1)`
  font-size: 14px;
  line-height: 18px;

  ${theme.mq.phone} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const Text0Bold = styled(Text0)`
  font-weight: 700;
`;

export const Text1Bold = styled(Text1)`
  font-weight: 700;
`;

export const Text2Bold = styled(Text2)`
  font-weight: 700;
`;

export const Caption = styled.p`
  margin: 0;
  font-family: ${theme.font.family.text};
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 0.004em;
`;
