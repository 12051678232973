import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Spinner from 'components/ui-elements/Spinner';
import { useSearchParams } from 'hooks/useSearchParams';
import { userService } from 'services/User/userService';

export const ActivateAccount = () => {
  const { token } = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    userService
      .activateAccount(token)
      .then(() => navigate('/activate-account-success'))
      .catch(() => navigate('/activate-account-error'));
  }, []);

  return <Spinner />;
};
