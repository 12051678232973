import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

export const H1 = styled.p`
  margin: 0;
  font-family: ${theme.font.family.header};
  font-size: 55px;
  line-height: 65px;
  font-weight: 600;
  display: block;

  ${theme.mq.phone} {
    font-size: 42px;
    line-height: 52px;
  }
`;

export const H2 = styled(H1)`
  font-size: 50px;
  line-height: 60px;

  ${theme.mq.phone} {
    font-size: 37px;
    line-height: 47px;
  }
`;

export const H3 = styled(H1)`
  font-size: 45px;
  line-height: 55px;

  ${theme.mq.phone} {
    font-size: 34px;
    line-height: 44px;
  }
`;

export const H4 = styled(H1)`
  font-size: 35px;
  line-height: 45px;

  ${theme.mq.phone} {
    font-size: 25px;
    line-height: 35px;
  }
`;

export const H5 = styled(H1)`
  font-size: 25px;
  line-height: 35px;

  ${theme.mq.phone} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const H6 = styled(H1)`
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  letter-spacing: 0.0015em;

  ${theme.mq.phone} {
    font-size: 16px;
    line-height: 19px;
  }
`;
