import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import ArrowIcon from 'assets/svg/arrow-down-red.svg';
import { Text1Bold } from 'components/typography/Texts';
import { useResponsive } from 'hooks/useResponsive';
import { useModal } from 'providers/ModalProvider';

import { SubMenuItem } from './SubMenuItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: unset;

  ${theme.mq.desktop} {
    align-items: center;
    flex-direction: row;
  }
`;

const MenuButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: unset;

  align-items: center;
  height: 50px;

  ${theme.mq.desktop} {
    flex-direction: column;
    height: unset;
    flex: unset;
  }
`;

const SubItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 2s ease;
  will-change: max-height;

  ${theme.mq.desktop} {
    flex-direction: row;
    position: relative;
    background-color: ${theme.color.white};
    flex: 1;
  }
`;

const MenuButton = styled(Text1Bold)<{ $isActive: boolean; $isDisabled?: boolean }>`
  display: flex;
  flex: 1;
  color: ${props => (props.$isActive ? theme.color.red : props.$isDisabled ? theme.color.disabled : theme.color.black)};

  cursor: ${props => (props.$isDisabled ? 'default' : 'pointer')};

  &:hover {
    color: ${props => (props.$isDisabled ? theme.color.disabled : theme.color.red)};
  }

  ${theme.mq.desktop} {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap;
  }
`;

const ActiveIndicator = styled.div`
  width: 8px;
  height: 50px;
  background: ${theme.color.red};
  border-radius: 50px 0 0 50px;

  ${theme.mq.desktop} {
    width: 50px;
    height: 8px;
    border-radius: 50px 50px 0 0;
  }
`;

const ArrowButton = styled.img<{ $isActive: boolean }>`
  position: absolute;
  display: flex;
  right: 24px;
  top: 20px;
  transform: ${props => (props.$isActive ? 'rotate(180deg)' : 'rotate(0deg)')};
  cursor: pointer;

  ${theme.mq.desktop} {
    right: 0;
    top: 26px;
    position: absolute;
  }
`;

interface SubItem {
  name: string;
  url: string;
}

interface Props {
  name: string;
  url?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  subItems?: SubItem[];
}

export const MenuItem = ({ name, url, onClick, isDisabled, subItems }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isDesktop } = useResponsive();
  const { showGetApp } = useModal();
  const isSelected = !!url && location.pathname === url;
  const menuItemRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(subItems?.some(subItem => subItem.url === location.pathname) ?? false);

  const handleOnClick = (event: MouseEvent) => {
    if (onClick) {
      event.preventDefault();
      onClick();
    } else if (!url) {
      showGetApp({ header: t('common:get-app.header-see'), description: name });
    }
  };

  const handleOnArrowClick = () => {
    setIsExpanded(currentState => !currentState);
  };

  useEffect(() => {
    if (isSelected && isDesktop) {
      const scrollTimeout = setTimeout(() => {
        if (!!menuItemRef.current) {
          menuItemRef.current.scrollIntoView({ inline: 'center', block: 'center' });
        }
      }, 0);

      return () => {
        clearTimeout(scrollTimeout);
      };
    }
  }, [isSelected]);

  const resolveSubItemsVisibility = () => {
    if (!isDesktop) {
      return isExpanded;
    } else {
      return true;
    }
  };

  return (
    <Container ref={menuItemRef} data-testid='my-profile-menu-item'>
      <MenuButtonContainer>
        <MenuButton as={Link} to={url || ''} onClick={handleOnClick} $isActive={isSelected} $isDisabled={isDisabled}>
          {name}
        </MenuButton>
        {subItems && !isDesktop && <ArrowButton src={ArrowIcon} onClick={handleOnArrowClick} $isActive={isExpanded} />}
        {isSelected && <ActiveIndicator data-testid='my-profile-menu-item-active' />}
      </MenuButtonContainer>
      {subItems && resolveSubItemsVisibility() && (
        <SubItemsContainer>
          {subItems.map(subItem => (
            <SubMenuItem key={subItem.name} name={t(subItem.name)} url={subItem.url} />
          ))}
        </SubItemsContainer>
      )}
    </Container>
  );
};
