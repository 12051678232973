import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { useLogout } from 'features/account';

import { MenuItem } from './MenuItem';

const Container = styled.div`
  display: flex;
  position: relative;
`;

const MenuItemsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 216px;
  margin: 36px 0 36px 24px;

  ${theme.mq.desktop} {
    flex-direction: row;
    margin: 0;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;

const Gradient = styled.div`
  width: 20px;
  height: 100%;
  position: absolute;
  background: linear-gradient(90deg, #ffffff00 0%, #ffffff 100%);
  display: none;

  ${theme.mq.desktop} {
    display: flex;
  }
`;

const GradientLeft = styled(Gradient)`
  left: -1px;
  transform: rotate(180deg);
`;

const GradientRight = styled(Gradient)`
  right: -1px;
`;

export const Menu = () => {
  const { t } = useTranslation('myProfile');
  const navigate = useNavigate();
  const { logout } = useLogout();

  const menuConfig = [
    {
      name: 'my-activity.header',
      url: '/my-profile/my-activity',
      subItems: [
        { name: 'myProfile:my-activity.campaigns', url: '/my-profile/my-activity/campaigns' },
        { name: 'myProfile:my-activity.hot-deals', url: '/my-profile/my-activity/hot-deals' },
      ],
    },
    { name: 'account-settings.header', isDisabled: true },
    { name: 'location.header', isDisabled: true },
    { name: 'shipping-address.header', url: '/my-profile/shipping-address' },
    { name: 'payment.header', url: '/my-profile/payment' },
    { name: 'contact-support.header', onClick: () => navigate('/messenger/context/SUPPORT') },
    { name: 'notifications.header', isDisabled: true },
    { name: 'passwords.header', isDisabled: true },
    { name: '2fa.header', isDisabled: true },
    { name: 'become-fundraiser.header', isDisabled: true },
    { name: 'logout', onClick: logout },
  ];

  return (
    <Container data-testid={'my-profile-menu'}>
      <MenuItemsWrapper>
        {menuConfig.map(({ name, url, subItems, onClick, isDisabled }) => (
          <MenuItem key={name} name={t(name)} url={url} subItems={subItems} onClick={onClick} isDisabled={isDisabled} />
        ))}
      </MenuItemsWrapper>
      <GradientLeft />
      <GradientRight />
    </Container>
  );
};
