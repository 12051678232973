import { useState } from 'react';
import styled from 'styled-components';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { theme } from 'assets/styles/theme';
import fullScreenIcon from 'assets/svg/fullscreen-icon.svg';
import heartIcon from 'assets/svg/heart-icon.svg';
import CarouselImage from 'components/carousel/CarouselImage';

import { CarouselFullScreen } from './CarouselFullScreen';

import 'swiper/css';
import 'swiper/css/pagination';

interface Props {
  photos: string[];
  isCampaign: boolean;
  onHeartClick?: () => void;
}

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
  border-radius: 5px;

  & .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .swiper-slide {
    width: 80%;
  }

  & .swiper-pagination-bullets {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 50px 50px 0;
    bottom: 0;

    ${theme.mq.huge} {
      padding: 0 30px 30px 0;
    }

    ${theme.mq.phone} {
      padding: 0 24px 24px 0;
    }
  }

  & .swiper-pagination-bullet {
    width: 13px;
    height: 13px;
    background-color: white;
    opacity: 0.8;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }

  & .swiper-pagination-bullet-active {
    width: 17px;
    height: 17px;
    opacity: 1;
  }
`;

const HeartIcon = styled.img`
  z-index: ${theme.zIndex.icon};
  position: absolute;
  top: 50px;
  right: 50px;
  cursor: pointer;

  ${theme.mq.desktop} {
    top: 30px;
    right: 30px;
  }

  ${theme.mq.phone} {
    top: 24px;
    right: 24px;
  }
`;

const FullScreenIcon = styled.img`
  z-index: ${theme.zIndex.icon};
  position: absolute;
  bottom: 50px;
  left: 50px;
  cursor: pointer;

  ${theme.mq.desktop} {
    bottom: 30px;
    left: 30px;
  }

  ${theme.mq.phone} {
    bottom: 24px;
    left: 24px;
  }
`;

const Container = styled.div`
  height: 605px;
  width: 100%;
  position: relative;

  border: 1px solid ${theme.color.darkGray};
  border-radius: 5px;

  ${theme.mq.desktop} {
    height: 515px;
  }

  ${theme.mq.tablet} {
    height: 400px;
  }

  ${theme.mq.phone} {
    height: 300px;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

const PhotoCarousel = ({ photos, isCampaign, onHeartClick }: Props) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreen = (isFullScreen: boolean) => {
    setIsFullScreen(isFullScreen);
  };

  return (
    <>
      {photos.length > 1 ? (
        <Container>
          <StyledSwiper
            data-testid={'photo-carousel'}
            slidesPerView={'auto'}
            spaceBetween={1}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}>
            {photos.map((photo, index) => (
              <SwiperSlide key={index}>
                <CarouselImage photo={photo} />
              </SwiperSlide>
            ))}
            {!isCampaign && <HeartIcon src={heartIcon} onClick={onHeartClick} />}
            <FullScreenIcon src={fullScreenIcon} onClick={() => setIsFullScreen(true)} />
            <CarouselFullScreen photos={photos} isFullScreen={isFullScreen} setIsFullScreen={handleFullScreen} />
          </StyledSwiper>
        </Container>
      ) : (
        photos.map((photo, index) => (
          <Container key={index}>
            <Image src={photo} data-testid={'image'} />
            {!isCampaign && <HeartIcon src={heartIcon} onClick={onHeartClick} />}
            <FullScreenIcon src={fullScreenIcon} onClick={() => setIsFullScreen(true)} />
            <CarouselFullScreen photos={photos} isFullScreen={isFullScreen} setIsFullScreen={handleFullScreen} />
          </Container>
        ))
      )}
    </>
  );
};

export default PhotoCarousel;
