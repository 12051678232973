import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { CAMPAIGNS_SLICE_SIZE } from 'assets/constants/listSizes';
import { theme } from 'assets/styles/theme';
import { CreateButton } from 'components/buttons/CreateButton';
import { RedTextButton } from 'components/buttons/RedTextButton';
import { LoadingContainer } from 'components/containers/Containers';
import { H4 } from 'components/typography/Headers';
import { LoadingIndicator } from 'components/ui-elements/LoadingIndicator';
import { useResponsive } from 'hooks/useResponsive';
import { useModal } from 'providers/ModalProvider';
import useMyCampaigns from 'services/Campaign/useMyCampaigns';

import CampaignTile from './campaign-tile/CampaignTile';
import { NoContent } from './NoContent';

const Container = styled.div`
  margin-top: 48px;

  ${theme.mq.phone} {
    margin-top: 24px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 24px;

  ${theme.mq.tablet} {
    gap: 12px;
  }
`;

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 24px;

  ${theme.mq.tablet} {
    gap: 12px;
  }
`;

export const CampaignsSection = () => {
  const { t } = useTranslation('myProfile');
  const { state } = useMyCampaigns();
  const { showGetApp } = useModal();
  const onCreateClick = () => showGetApp({ appAreaType: 'ADD_CAMPAIGN' });
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const renderCampaigns = () => (
    <TilesContainer>
      {state.campaigns.slice(0, CAMPAIGNS_SLICE_SIZE).map(campaign => (
        <CampaignTile key={campaign.id} campaign={campaign} onClick={() => navigate(`/campaigns/${campaign.id}`)} />
      ))}
    </TilesContainer>
  );

  const renderNoContent = () => (
    <NoContent
      text={t('my-activity.no-campaigns')}
      buttonText={t('my-activity.create-first')}
      onClick={onCreateClick}
    />
  );

  const resolveButtonText = () => (isMobile ? t('my-activity.create') : t('my-activity.create-campaign'));

  const renderLoading = () => (
    <LoadingContainer>
      <LoadingIndicator />
    </LoadingContainer>
  );

  const renderContent = () => {
    if (state.loading) return renderLoading();
    if (state.campaigns && state.campaigns.length > 0) return renderCampaigns();
    return renderNoContent();
  };

  return (
    <Container>
      <HeaderContainer>
        <H4>{t('my-activity.campaigns')}</H4>
        <CreateButton onClick={onCreateClick}>{resolveButtonText()}</CreateButton>
        <RedTextButton onClick={() => navigate('/my-profile/my-activity/campaigns')}>
          {t('my-activity.see-all')}
        </RedTextButton>
      </HeaderContainer>
      {renderContent()}
    </Container>
  );
};
