import { useState } from 'react';
import styled from 'styled-components';

import { CarouselFullScreen } from 'components/carousel/CarouselFullScreen';
import { LoadingIndicator } from 'components/ui-elements/LoadingIndicator';
import { InternalMessageDto, MessageInternalStatus } from 'services/Message/messageService.dto';

import { MessageDetails } from './MessageDetails';

const MessageImage = styled.img<{ success: boolean }>`
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  opacity: ${props => (props.success ? 1.0 : 0.3)};
`;

const Container = styled.div<{ isMessageOwner: boolean }>`
  max-width: 220px;
  margin-left: ${props => (props.isMessageOwner ? 'auto' : '0')};
  margin-right: ${props => (props.isMessageOwner ? '0' : 'auto')};
  position: relative;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  position: absolute;
  top: 40%;
  right: 45%;
`;

interface Props {
  message: InternalMessageDto;
  isMessageOwner: boolean;
}

export const PhotoMessage = ({ message, isMessageOwner }: Props) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreen = (isFullScreen: boolean) => {
    setIsFullScreen(isFullScreen);
  };
  return (
    <Container isMessageOwner={isMessageOwner}>
      {message.internalStatus === MessageInternalStatus.UPLOADING && <StyledLoadingIndicator />}
      <MessageImage
        data-testid={'message-image'}
        src={message.mediaUrl}
        alt={'msg-img'}
        onClick={() => setIsFullScreen(true)}
        success={true}
      />
      <CarouselFullScreen
        photos={[message.mediaUrl!!]}
        isFullScreen={isFullScreen}
        setIsFullScreen={handleFullScreen}
      />
      <MessageDetails message={message} isMessageOwner={isMessageOwner} />
    </Container>
  );
};
