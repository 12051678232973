const BREAKPOINT_MIN = 0;
const BREAKPOINT_PHONE = 767;
const BREAKPOINT_TABLET = 960;
const BREAKPOINT_DESKTOP = 1150;
const BREAKPOINT_HUGE = 1440;
const BREAKPOINT_MAX = 1920;

export const theme = {
  font: {
    family: {
      header: 'SFProDisplay',
      text: 'SFProText',
    },
  },
  color: {
    white: '#fff',
    lightBlack: '#1f1f1f',
    disabled: '#c2cacc',
    black: '#000',
    red: '#fc686f',
    smokedRed: '#d25762',
    whiteSmoke: '#e9e9e9',
    lighterGray: '#f4f6f6',
    lighterGray2: '#818181',
    placeholderGrey: '#7B8486',
    lightGray: '#e4e4e7',
    gray: '#E8E8E8',
    darkGray: '#c4cacc',
    darkerGray: '#98AAB5',
    darkerGray2: '#848484',
    darkestGray: '#818181',
    silver: '#e9eaed',
    darkBlue: '#40667D',
    blue: '#40629F',
    lightCyan: '#F8FBFF',
    borderGray: '#e7eaeb',
    overlay: '#1f1f1fcc',
    pink: '#fc686e',
    green: '#409F84',
    lightGreen: '#a5e04c',
    socialMediaGoogle: '#5b89ee',
    socialMediaFacebook: '#1877f2',
    socialMediaApple: '#1f1f1f',
    notificationGreen: '#80BC53',
    messageGray: '#969FA2',
  },
  zIndex: {
    foremost: 999,
    modal: 998,
    navbar: 7,
    notification: 997,
    icon: 1,
  },
  mq: {
    phone: `@media (max-width: ${BREAKPOINT_PHONE}px)`,
    tablet: `@media (max-width: ${BREAKPOINT_TABLET}px)`,
    desktop: `@media (max-width: ${BREAKPOINT_DESKTOP}px)`,
    huge: `@media(max-width: ${BREAKPOINT_HUGE}px)`,
    default: `@media(max-width: ${BREAKPOINT_MAX}px)`,
  },
  breakpoint: {
    min: BREAKPOINT_MIN,
    phone: BREAKPOINT_PHONE,
    tablet: BREAKPOINT_TABLET,
    desktop: BREAKPOINT_DESKTOP,
    huge: BREAKPOINT_HUGE,
    max: BREAKPOINT_MAX,
  },
  maxWidthContainer: 1680,
  paddingStandard: 15,
  borderRadiusStandard: 6,
  sectionSize: {
    small: 420,
    standard: 500,
    big: 660,
  },
};
