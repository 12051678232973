import { NavigateFunction } from 'react-router-dom';

export const resolveTileClick = (filter: string, navigate: NavigateFunction, itemId: number, orderId?: number) => {
  if (filter === 'selling') {
    navigate(`/items/${itemId}`);
  } else if (filter === 'donated') {
    navigate(`/my-profile/my-activity/hot-deals/${itemId}`);
  } else if (filter === 'bought') {
    navigate(`/my-profile/my-activity/hot-deals/${itemId}/${orderId}`);
  }
};
