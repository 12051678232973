import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text2Bold } from 'components/typography/Texts';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: unset;

  align-items: center;
  height: 30px;

  ${theme.mq.desktop} {
    flex-direction: column;
    height: unset;
    flex: unset;
  }
`;

const SubMenuButton = styled(Text2Bold)<{ $isSelected: boolean }>`
  display: flex;
  flex: 1;
  color: ${props => (props.$isSelected ? theme.color.red : theme.color.black)};

  ${theme.mq.desktop} {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap;
  }

  &:hover {
    color: ${theme.color.red};
  }
`;

const ActiveIndicator = styled.div`
  width: 8px;
  height: 30px;
  background: ${theme.color.red};
  border-radius: 50px 0 0 50px;

  ${theme.mq.desktop} {
    width: 50px;
    height: 8px;
    border-radius: 50px 50px 0 0;
  }
`;

interface Props {
  name: string;
  url: string;
  onClick?: () => void;
}

export const SubMenuItem = ({ name, url, onClick }: Props) => {
  const location = useLocation();
  const isSelected = !!url && location.pathname === url;

  return (
    <Container data-testid={'my-profile-menu-sub-item'} onClick={onClick}>
      <SubMenuButton as={Link} to={url} $isSelected={isSelected}>
        {name}
      </SubMenuButton>
      {isSelected && <ActiveIndicator data-testid={'my-profile-menu-item-active'} />}
    </Container>
  );
};
