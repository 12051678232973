import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { CreateButton } from 'components/buttons/CreateButton';
import { H4 } from 'components/typography/Headers';
import { PageWrapper } from 'features/my-profile/components/PageWrapper';
import { useResponsive } from 'hooks/useResponsive';
import { useModal } from 'providers/ModalProvider';
import useMyItems from 'services/useMyItems';

import { FilterButtons } from './components/FilterButtons';
import { LoadMore } from './components/LoadMore';
import { MyActivityItemTile } from './components/MyActivityItemTile';
import { NoContent } from './components/NoContent';
import { ReturnButton } from './components/ReturnButton';
import { resolveTileClick } from './utils/resolveTileClick';

const Container = styled.div`
  display: flex;
  gap: 24px;

  ${theme.mq.phone} {
    width: 100%;
    justify-content: space-between;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 48px;
  align-items: center;
  margin-bottom: 24px;

  ${theme.mq.phone} {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 12px;
  }
`;

const TileContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 24px;

  ${theme.mq.tablet} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 12px;
  }

  ${theme.mq.phone} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const MyActivityHotDealsPage = () => {
  const { t } = useTranslation('myProfile');
  const { showGetApp } = useModal();
  const onAddClick = () => showGetApp({ appAreaType: 'ADD_ITEM' });
  const { fetchNextPage, state, handleFilterChange, filter } = useMyItems();
  const { isMobile } = useResponsive();
  const navigate = useNavigate();

  const renderNoContent = () => (
    <NoContent text={t('my-activity.no-items')} buttonText={t('my-activity.add-first')} onClick={onAddClick} />
  );

  const resolveButtonText = () => (isMobile ? t('my-activity.add') : t('my-activity.add-item'));

  const renderItems = () => (
    <>
      <TileContainer>
        {state.items.map(item => (
          <MyActivityItemTile
            key={`${item.id}_${item.orderId}`}
            item={item}
            onClick={(itemId, orderId) => resolveTileClick(filter, navigate, itemId, orderId)}
          />
        ))}
      </TileContainer>
    </>
  );

  const renderContent = () => {
    if (state.items && state.items.length > 0) return renderItems();
    return renderNoContent();
  };

  return (
    <PageWrapper testId={'my-profile-my-activity-hot-deals'}>
      <ReturnButton />
      <HeaderContainer>
        <Container>
          <H4>{t('my-activity.hot-deals')}</H4>
          <CreateButton onClick={onAddClick}>{resolveButtonText()}</CreateButton>
        </Container>
        <FilterButtons handleFilterChange={handleFilterChange} />
      </HeaderContainer>
      {renderContent()}
      <LoadMore hasNextPage={state.hasNextPage} loading={state.loading} onClick={fetchNextPage} />
    </PageWrapper>
  );
};
