import { createGlobalStyle } from 'styled-components';

import { theme } from './theme';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html {
    font-size: 62.5%;
  }
  
  body {
    font-size: 1.6rem;
    font-family: ${theme.font.family.text};
  }

  button, input {
    font: inherit;
    line-height: 1;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    font-family: ${theme.font.family.text};
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    ${theme.mq.phone} {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export default GlobalStyle;
