import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import SpinnerSource from 'assets/gif/spinner.svg';
import { theme } from 'assets/styles/theme';
import { Text1 } from 'components/typography/Texts';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 35px;
`;

const Text = styled(Text1)`
  padding-top: 10px;
  color: ${theme.color.darkerGray2};
`;

interface Props {
  displayLabel?: boolean;
  className?: string;
}

const Spinner = ({ displayLabel, className }: Props) => {
  const { t } = useTranslation('common');
  return (
    <Container data-testid={'spinner'} className={className}>
      <Image src={SpinnerSource} />
      {displayLabel && <Text>{t('loading')}</Text>}
    </Container>
  );
};

export default Spinner;
