import { ActivateAccount } from 'features/activate-account/ActivateAccount';
import { ActivateAccountError } from 'features/activate-account/ActivateAccountError';
import { ActivateAccountSuccess } from 'features/activate-account/ActivateAccountSuccess';
import { CampaignPreview } from 'features/campaign/CampaignPreview';
import { CheckoutSuccess, ItemCheckout } from 'features/checkout';
import { PageNotFound } from 'features/error/PageNotFound';
import { PaymentDeclined } from 'features/error/PaymentDeclined';
import { Home } from 'features/home/Home';
import { ItemPreview } from 'features/item/ItemPreview';
import { MessagePreview } from 'features/message/MessagePreview';
import { Messenger } from 'features/messenger/Messenger';
import { MyActivityCampaignsPage } from 'features/my-profile/my-activity/MyActivityCampaignsPage';
import { MyActivityHotDealsPage } from 'features/my-profile/my-activity/MyActivityHotDealsPage';
import { MyActivityPage } from 'features/my-profile/my-activity/MyActivityPage';
import { MyActivityTrackedItemPage } from 'features/my-profile/my-activity/MyActivityTrackedItemPage';
import { MyProfile } from 'features/my-profile/MyProfile';
import { PaymentPage } from 'features/my-profile/payment/PaymentPage';
import { ShippingAddressPage } from 'features/my-profile/shippingAddress/ShippingAddressPage';
import { ResetPassword } from 'features/reset-password/ResetPassword';
import { ResetPasswordError } from 'features/reset-password/ResetPasswordError';
import { ResetPasswordSuccess } from 'features/reset-password/ResetPasswordSuccess';
import { Download } from 'features/user/Download';
import { GivePhone } from 'features/user/GivePhone';
import { Thanks } from 'features/user/Thanks';
import { UnsubscribeEmail } from 'features/user/UnsubscribeEmail';

import PrivateRoute from './PrivateRoute';

export const routesConfig = [
  { path: '/', element: <Home /> },
  { path: '/campaigns/:campaignName/:campaignId', element: <CampaignPreview /> },
  { path: '/campaigns/:campaignId', element: <CampaignPreview /> },
  { path: '/items/:itemId/checkout', element: <ItemCheckout /> },
  { path: '/items/:itemName/:itemId', element: <ItemPreview /> },
  { path: '/items/:itemId', element: <ItemPreview /> },
  { path: '/checkout-success', element: <PrivateRoute />, children: [{ path: '', element: <CheckoutSuccess /> }] },
  { path: '/messages/from/:senderId', element: <MessagePreview /> },
  { path: '/give-phone', element: <GivePhone /> },
  { path: '/payment-declined', element: <PrivateRoute />, children: [{ path: '', element: <PaymentDeclined /> }] },
  { path: '/thanks', element: <Thanks /> },
  { path: '/reset-password', element: <ResetPassword /> },
  { path: '/reset-password-success', element: <ResetPasswordSuccess /> },
  { path: '/reset-password-error', element: <ResetPasswordError /> },
  { path: '/activate-account-success', element: <ActivateAccountSuccess /> },
  { path: '/activate-account-error', element: <ActivateAccountError /> },
  { path: '/activate-account', element: <ActivateAccount /> },
  { path: '/download', element: <Download /> },
  { path: '/unsubscribe', element: <UnsubscribeEmail /> },
  {
    path: '/my-profile',
    element: <PrivateRoute />,
    children: [
      { path: '', element: <MyProfile /> },
      { path: 'my-activity', element: <MyActivityPage /> },
      { path: 'my-activity/campaigns', element: <MyActivityCampaignsPage /> },
      { path: 'my-activity/hot-deals', element: <MyActivityHotDealsPage /> },
      { path: 'shipping-address', element: <ShippingAddressPage /> },
      { path: 'payment', element: <PaymentPage /> },
      { path: 'my-activity/hot-deals/:itemId/:orderId?', element: <MyActivityTrackedItemPage /> },
    ],
  },
  {
    path: '/messenger',
    element: <PrivateRoute />,
    children: [
      { path: '', element: <Messenger /> },
      { path: ':conversationId', element: <Messenger /> },
      { path: 'context/:context', element: <Messenger /> },
    ],
  },
  { path: '*', element: <PageNotFound /> },
];
