import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

export const Container = styled.div`
  background-color: ${theme.color.white};
  width: 100%;
  display: flex;
  border-top: 1px solid ${theme.color.darkGray};
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: ${theme.maxWidthContainer}px;
  padding: 48px 48px;
  margin: 0 auto;

  ${theme.mq.phone} {
    padding: 12px 12px;
    flex-direction: column-reverse;
  }
`;
