import styled from 'styled-components';

const Image = styled.img`
  position: absolute;
  top: -9999px;
  left: -9999px;
  right: -9999px;
  bottom: -9999px;
  margin: auto;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1;
`;

interface Props {
  photo: string;
}

const CarouselImage = ({ photo }: Props) => {
  return (
    <>
      <Image src={photo} alt={'Photo'} />
    </>
  );
};

export default CarouselImage;
